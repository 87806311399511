import React from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import Layout from '../layouts/Layout'
import SiteMetadata from '../components/SiteMetadata'
import { useSiteInfoQuery } from '../queries/useSiteInfoQuery'
import { MarkdownProcessor } from '../components/Markdown/MarkdownProcessor'
import { theme } from '../styles/theme'

const ContactPage = () => {
    const { siteDescription, impressum } = useSiteInfoQuery()

    const stylez = css`
            width: 50%;
            margin: 0 auto;
            font-size: ${ theme.fontSizes.medium };
            a {
              color: ${ theme.colors.secondary };
              :visited {
                color: ${ theme.colors.secondary };
              }
            }
            ${ theme.breakpoints.mobile } {
              width: 95%;
              font-size: ${ theme.fontSizes.small };
              margin-top: 2rem;
            }
            ${ theme.breakpoints.tablet } {
              width: 85%;
              font-size: ${ theme.fontSizes.small };
            }
            h1 {
              ${ theme.breakpoints.mobile } {
                  font-size: 1.5rem !important;
                }
                ${ theme.breakpoints.tablet } {
                  font-size: 1.5rem !important;
                }
            }
            p{
              font-size: 1.2rem;
              line-height: 1.4;
              ${ theme.breakpoints.mobile } {
                  font-size: 1rem;
                  line-height: 1.2;
                }
                ${ theme.breakpoints.tablet } {
                  font-size: 1rem;
                  line-height: 1.2;
                }
            }
   `

    return (
        <Layout>
            <SiteMetadata title='Kontakt' description={ siteDescription } />

            <div
              className='text_content impressum_wrapper'
              css={ stylez }
            >

                <div className='impressum_wrapper'>
                    <MarkdownProcessor
                      markdownElement={ impressum }
                    />
                </div>

            </div>

        </Layout>
    )
}

export default ContactPage
